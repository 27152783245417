import React, { useState, useEffect } from 'react'
import './DashboardTwo.css'
import MenuBar from '../MenuBar/MenuBar'
import {
  getUserData,
  getEnrollmentId,
  getCourseByCourseId,
  getAllReccomendedCourse,
  getActivityByCategory,
  randomCourses,
  getMembershipBundle
} from "../../utls/utls";
import { Link } from "react-router-dom";
import NewsCard from "../Shared/NewsCard/NewsCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NewsData from "./NewsData";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import StudentReport from '../Shared/StudentReport/StudentReport';
import Loader from '../Shared/Loader/Loader';
import { Backdrop } from '@mui/material';


const DashboardTwo = () => {
  const [course, setCourse] = useState([]);
  const [reccomendedCourse, setReccomendedCourse] = useState([]);
  const [activityCourse, setActivityCourse] = useState([]);
  const [randomCourse, setRandomCourse] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [userData, setUserData] = useState({
    first_name: "",
    name: "",
    user_image: "",
  });
  const [memberCourse, setMemberCourse] = useState([])
  const memberShipId = [127466, 193649, 193939]
  const user_id = localStorage.getItem("id");
  const randomNum = JSON.parse(localStorage.getItem("RandomNo"))
  console.log(memberCourse)
  console.log('Type conversion', Number(" 1  "))

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    // arrows: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
    ],
  };
  console.log(randomCourse)
  const getCourses = async (user_id) => {
    const random = await randomCourses()
    setRandomCourse(random?.data?.items)
    const res = await getEnrollmentId(user_id);
    const courses = await getCourseByCourseId(res?.data?.items);
    const activitData = await getActivityByCategory()
    const data = courses?.filter((elm) => {
      return elm !== undefined && elm?.collection?.name !== 'Life Skills';
    });
    setCourse(data);

    setActivityCourse(activitData);
    const userAcademicData = await getUserData(user_id);
    const { semester, university, branch } =
      userAcademicData.data.user[0];
    const recc = await getAllReccomendedCourse({
      semester,
      university,
      branch,
    });

    // 2199464
    let reccData = recc?.data?.courseList;
    reccData?.push({ course_id: 2199464 });
    const reccCourses = await getCourseByCourseId(reccData);
    const recCourseData = reccCourses?.filter((elm) => {
      return elm !== undefined && courses?.filter((element) => {
        return elm?.course?.id !== element?.course?.id
      });
    });
    const result = recCourseData?.filter((course) => !courses?.some((recc) => course?.course?.id === recc?.course?.id))

    setReccomendedCourse(result);
    const memberCourseResp = await getMembershipBundle(memberShipId)
    // console.log(memberCourseResp)
    setMemberCourse(memberCourseResp)
  };

  const userDetails = async (user_id) => {
    try {
      // console.log(JSON.parse(window.sessionStorage.getItem("userThincific")))
      const res = JSON.parse(window.sessionStorage.getItem("userThincific"));
      setUserData({
        ...userData,
        first_name: res?.data?.first_name,
        name: res?.data?.full_name,
        user_image: res?.data?.avatar_url,
      });
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses(user_id);
    userDetails(user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  // Carousel next arrow
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowRight className={className} onClick={onClick} style={{ ...style, color: "grey" }} />
    );
  }

  // Carousel prev arrow
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowLeft className={className} onClick={onClick} style={{ ...style, color: "grey" }} />
    );
  }

  return (
    <>
      <Backdrop open={showModal} style={{ zIndex: "1" }} />
      {(randomCourse.length === 0) ? <Loader /> : (
        <div className='dashboardTwo_container'>
          <MenuBar userData={userData} />
          {/* <h1 style={{ fontSize: '3rem', margin: '1.2rem 0' }}>Hello, {userData?.first_name}!</h1> */}
          <div className="userName">
            <div className="hello">
              <h3>Hello, {userData?.first_name}!</h3>
            </div>
            <div className="welcome">
              <h5>Welcome Back</h5>
            </div>
          </div>

          <div className='dashboard_hero'>
            {/* Dashboard left section */}
            <div className='dashboard_left'>

              {/* Your courses section */}
              {course?.length > 0 && <h1 className='card_main_heading'>Your Courses </h1>}
              <div className='yourCourses_section'>
                {(course.length > 0) && course?.map((elm, index) => {
                  return <CourseCard data={elm} key={index} />;
                })}
              </div>

              <div className='pathway_container'>
                <h1 className='card_main_heading'>Explore these Courses</h1>
                <div className="course_content">
                  {randomCourse?.slice(randomNum.arrayStartNo, randomNum.arrayStartNo + 6)?.map((elm, index) => (
                    <YourCourseCard data={elm} key={index} />
                  ))}
                </div>
              </div>

              {reccomendedCourse?.length > 0 && <h1 className='card_main_heading'>Recommended to take in this semester</h1>}
              <div className='pathway_container'>
                <div className="course_content">
                  {reccomendedCourse?.map((elm, index) => (
                    <CourseCard data={elm} key={index} />
                  ))}
                </div>
              </div>
            </div>

            {/* Dashboard right section */}
            <div className='dashboard_right'>
              <h1 style={{ textAlign: 'left', margin: '1rem' }}>Life Skills</h1>
              <div className='activity_section'>
                {activityCourse?.map((elm, index) => {
                  return <ActivityCircle data={elm} key={index} />
                })}
              </div>

              {/* Industry news section */}
              {/* <div className='industry_news'> */}
              <h1 style={{ marginTop: '2rem' }}>Industry News</h1>
              {/* </div> */}
              <div className="news_container">
                <Slider {...settings} className="slide">
                  {NewsData.map((elm, index) => {
                    return <NewsCard data={elm} key={index} />;
                  })}
                </Slider>
              </div>
              <StudentReport course={course} setShowModal={setShowModal} showModal={showModal} />
            </div>
          </div>
          {/* <div className='pathway_container'>
            <h1 className='card_main_heading' style={{ textAlign: "center" }}>Membership Plans</h1>
            <div className="course_content">
              {memberCourse?.map((elm, index) => (
                <MembershipCard data={elm} key={index} />
              ))}
            </div>
          </div> */}
        </div>
      )}
    </>
  )
}

export default DashboardTwo;

const YourCourseCard = ({ data }) => {

  const course_page_url = `https://learn.mindmatrix.io/courses/${data?.slug}`;
  const percentage = Math.floor(data?.percentage_completed);

  return (
    <>
      <Link
        to={course_page_url}
        style={{ textDecoration: "none" }}
        className='course_card courseCard_container'
      >
        {/* <div className='yourCourseCard_Container'> */}
        <div
          style={{
            backgroundImage: `url(${data?.card_image_url})`,
          }}
          className='course_card_img'
        ></div>
        <h1 className="course_name">{data?.name}</h1>
        <p className="course_category" style={{ fontSize: '1.3rem', margin: '0.3rem 0' }}>{data?.collection?.name}</p>
        <div className="course_card_footer">
          {(data?.percentage_completed) ? <h1 className='percentage_completed'>{percentage}% completed</h1> : null}
        </div>
      </Link>
    </>
  )
}

// const MembershipCard = ({ data }) => {
//   const course_page_url = `https://learn.mindmatrix.io/bundles/${data?.slug}`;
//   return (
//     <>
//       <Link
//         to={course_page_url}
//         style={{ textDecoration: "none" }}
//         className='course_card courseCard_container'
//       >
//         {/* <div className='yourCourseCard_Container'> */}
//         <div
//           style={{
//             backgroundImage: `url(${data?.bundle_card_image_url})`,
//             height: "22rem"
//           }}
//           className='course_card_img'
//         ></div>
//         <h1 className="course_name" style={{ textAlign: "center", fontWeight: '700' }}>{data?.name}</h1>
//         <p className="course_category" style={{ fontSize: '1.3rem', margin: '0.3rem 0' }}>{data?.collection?.name}</p>
//       </Link>
//     </>
//   )
// }
const CourseCard = ({ data }) => {

  const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
  const percentage = Math.floor(data?.percentage_completed);

  return (
    <>
      <Link
        to={course_page_url}
        className="course_card courseCard_container"
        style={{ textDecoration: "none" }}
      >
        <div
          className="course_card_img"
          style={{
            backgroundImage: `url(${data?.product?.card_image_url})`,
          }}
        ></div>
        <h1 className="course_name">{data?.course?.name}</h1>
        <p className="course_category" style={{ fontSize: '1.3rem', margin: '0.3rem 0' }}>{data?.collection?.name}</p>
        <div className="course_card_footer">
          {(data?.percentage_completed) ? <h1 className='percentage_completed'>{percentage}% completed</h1> : null}
        </div>
        {/* <p className="course_category" >{daysLeft ? <h1 style={{ fontSize: '1.8rem', fontFamily: 'Montserrat', textAlign: 'left', margin: '0.3rem 0' }}>Days left - {daysLeft} </h1> : null}</p> */}
      </Link>
    </>
  )
}

const ActivityCircle = ({ data }) => {
  const url = `https://learn.mindmatrix.io/courses/${data.slug}`

  return (
    <>
      <Link to={url} className='circle_container_parent'>
        <div className='circle_container' style={{ backgroundImage: `url(${data?.card_image_url})` }}>
          <div className='circle_overlay'>
            <h1 className="course_name" style={{ textAlign: 'center', color: 'white', marginTop: '2.5rem', fontSize: '1.5rem' }}>{data?.name}</h1>
          </div>
        </div>
      </Link>
    </>
  )
}

