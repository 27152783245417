import React from 'react'
import { Routes, Route } from 'react-router-dom'
import StudentDetails from './Component/StudentDetails/StudentDetails'
import Dashboard from './Component/Dashboard/Dashboard'
import LandingPage from './Component/LandingPage/LandingPage'
import Home from './Component/Home/Home'
// import Reccomendation from './Component/Rccomendation/Reccomendation'
import Assignment from './Component/Assignment/Assignment'
import AdminDashboard from './AdminComponent/AdminDashboard/AdminDashboard'
import UserAssistance from './Component/UserAssistance/UserAssistance'
import SendCustomMail from './AdminComponent/SendCustomMail/SendCustomMail'
import ActivityPost from './Component/ActivityPost/ActivityPost'
import Contact from './Component/Contact/Contact'
import UserProgress from './Component/Dashboard/UserProgress/UserProgress'
import ActivityPage from './Component/ActivityPage/ActivityPage'
import ActivityEvaluation from './Component/ActivityEvaluation/ActivityEvaluation'
import UserBranchPrefance from './Component/UserBranchPrefance/UserBranchPrefance'
import UserDomainPrefance from './Component/UserDomainPrefance/UserDomainPrefance'
import LandingPageTwo from './Component/LandingPageTwo/LandingPageTwo'
import ViewAllCourses from './Component/ViewAllCourses/ViewAllCourses'
import Maintenance from './Component/Maintenance/Maintenance'
import DashboardTwo from './Component/DashboardTwo/DashboardTwo'
import Report from './Component/Shared/Report/Report'
import Graph from './Component/Shared/Graph/Graph'
import TeacherDashboard from './Component/TeacherDashboard/TeacherDashboard'
import UserAccount from './Component/UserAccount/UserAccount'

const App = () => {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path='/landingPage' element={<LandingPage />} />
          <Route path='/' element={<LandingPageTwo />} />
          <Route path='/viewAllCourses' element={<ViewAllCourses />} />
          <Route path='/studentDetails' element={<StudentDetails />} />
          <Route path='/studentBranch' element={<UserBranchPrefance />} />
          <Route path='/studentDomain' element={<UserDomainPrefance />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboardTwo' element={<DashboardTwo />} />
          <Route path='/teacher-dashboard' element={<TeacherDashboard />} />
          <Route path='/user-account/:id' element={<UserAccount />} />
          <Route path='/userProgress' element={<UserProgress />} />
          <Route path='/report' element={<Report />} />
          <Route path='/adminDashboard' element={<AdminDashboard />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/home/:id' element={<Home />} />
          {/* <Route path='/ai-rec' element={ <Reccomendation /> }/> */}
          <Route path='/user-assistance' element={<UserAssistance />} />
          <Route path='/custom-mail' element={<SendCustomMail />} />
          <Route path='/activity-post/:id' element={<ActivityPost />} />
          <Route path='/assignment/:courseName/:chapterName' element={<Assignment />} />
          <Route path='/activity/:courseName/:chapterName' element={<ActivityPage />} />
          <Route path='/activity-evaluation' element={<ActivityEvaluation />} />
          <Route path='/maintenance' element={<Maintenance />} />
          <Route path='/graph' element={<Graph />} />
        </Routes>
      </div>
    </>
  )
}

export default App;


// Used components :-
// LandingPage
// LandingPageTwo
// ViewAllCourses
// StudentDetails
// UserBranchPrefance
// UserDomainPrefance
// Dashboard
// DashboardTwo
// TeacherDashboard
// UserAccount
// UserProgress
// Report
// AdminDashboard
// Contact
// Home
// UserAssistance
// SendCustomMail
// ActivityPost
// Assignment
// ActivityPage
// ActivityEvaluation
// Maintenance
// Graph
